import React from "react";
import styled from "styled-components";
import { kebabCase } from "lodash";

const Variant = styled.div`
  display: block;
  padding-bottom: 30px;
  label {
    margin-right: 20px;
    text-transform: capitalize;
  }
  select {
    border: none;
    padding: 10px;
    background: transparent;
    font-size: 14px;
    font-family: inherit;
    color: inherit;
  }
`;

export const Select = ({ label, variantOptions, onChange}) => (
  <Variant>
    <label htmlFor={`variant-${kebabCase(label)}`}>{label}:</label>
    <select id={`variant-${kebabCase(label)}`} onChange={onChange}>
      {variantOptions.map(variant => (
        <option value={kebabCase(variant)} key={kebabCase(variant)}>{variant}</option>
      ))}
    </select>
  </Variant>
);
