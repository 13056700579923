import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import styled from "styled-components";
import { colors } from "../styles/variables";
import ShoppingIcon from "../assets/shopping-bag.svg";
import { Tags } from "../components/Tags";
import { Select } from "../components/Select";
import EmblaCarouselReact from "embla-carousel-react";
import { EmblaButtons } from "../components/EmblaButtons";
import { uniq } from "lodash";

const Title = styled.h3`
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 24px;
  &:after {
    background: ${colors.accent};
    margin-top: 15px;
    display: block;
    width: 30px;
    height: 2px;
    content: "";
  }
`;

const Price = styled.p`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  overflow: hidden;
  padding-right: 20px;
  padding-left: 20px;
  line-height: 50px;
  height: 50px;
  font-size: 18px;
  letter-spacing: 0.2em;
  margin-bottom: 15px;
  border-top: 1px dashed ${colors.lightGrey};
  border-bottom: 1px dashed ${colors.lightGrey};
  margin: 20px 0;
`;

const Input = styled.input`
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 50px;
  border-bottom: 1px dashed ${colors.lightGrey};
  border-top: 1px dashed ${colors.lightGrey};
  border-left: none;
  border-right: none;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
  font-size: 13px;
  color: #adadad;
  width: 65px;
`;

const Button = styled.button`
  vertical-align: middle;
  background-color: ${colors.gold};
  border: solid 2px ${colors.gold};
  color: #fff;
  text-transform: uppercase;
  border-radius: 0;
  line-height: 50px;
  height: 50px;
  position: relative;
  padding: 0 25px;
  letter-spacing: 0.2em;
  font-weight: 700;
  font-size: 13px;
  margin-left: 20px;
  display: inline-flex;
  transition: 0.45s;
  &:hover {
    background: white;
    color: ${colors.gold};
    svg path {
      fill: ${colors.gold};
    }
  }
  svg {
    width: 25px;
    margin: 10px;
    path {
      fill: white;
      transition: 0.45s;
    }
  }
`;

const Slider = styled.div`
  display: flex;
  & > div {
    flex: 0 0 auto;
    min-width: 100%;
  }
`;

const createVariantOptions = (variants, originalPrice) => {
  return variants
    .map(({ variantPrice, variantName }) => {
      const price = variantPrice ? variantPrice - originalPrice : 0;
      return `${variantName}[${price > 0 ? "+" : "-"}${price}]`;
    })
    .join("|");
};

export const ProductPageTemplate = ({
  featuredImage,
  title,
  description,
  price,
  shortDescription,
  tags,
  id,
  images,
  variantLabel,
  variants
}) => {
  const allImages = [
    featuredImage,
    ...images.map(img => img.src)
  ];
  const [embla, setEmbla] = useState(null);
  const [variantOptions, setVariantOptions] = useState(
    createVariantOptions(variants, price)
  );

  const [selectedVariant, setSelectedVariant] = useState(variants[0]);
  const scrollTo = useCallback(index => embla.scrollTo(index), [embla]);

  const variantSelected = event => {
    const selectedIndex = event.currentTarget.selectedIndex;
    const orderedVariants = uniq([variants[selectedIndex], ...variants]);
    setSelectedVariant(variants[selectedIndex]);
    setVariantOptions(createVariantOptions(orderedVariants, price));
  };

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div
              className="column"
              style={{
                margin: "0 50px",
                position: "relative",
                height: "max-content"
              }}
            >
              <EmblaCarouselReact
                emblaRef={setEmbla}
                options={{ align: "start", loop: true }}
              >
                <Slider>
                  {allImages.map((image, index) => (
                    <PreviewCompatibleImage key={index} imageInfo={{ image }} />
                  ))}
                </Slider>
                {allImages.length > 1 && (
                  <EmblaButtons embla={embla} size="30" />
                )}
              </EmblaCarouselReact>
            </div>
            <div className="column">
              <Title className="title">{title}</Title>
              <p style={{ color: colors.textColor }}>{shortDescription}</p>
              <div>
                <Price>
                  £{selectedVariant ? selectedVariant.variantPrice || price : price}
                </Price>
              </div>
              {variants.length > 0 && (
                <Select
                  label={variantLabel || ""}
                  onChange={variantSelected}
                  variantOptions={variants.map(variant => variant.variantName)}
                />
              )}
              <Input
                step="1"
                min="1"
                max="9999"
                name="quantity"
                defaultValue="1"
                type="number"
              />
              <Button
                className="snipcart-add-item"
                data-item-id={id}
                data-currency="gbp"
                data-item-price="79.99"
                data-item-url={`shop/product/${title}`}
                data-item-description={shortDescription}
                data-item-image={featuredImage.childImageSharp.fluid.src}
                data-item-name={title}
                data-item-custom1-name={variantLabel}
                data-item-custom1-options={variantOptions}
              >
                <ShoppingIcon />
                <span>Add to cart</span>
              </Button>
              {tags && <div>
                <Tags tags={tags} />
              </div>}
            </div>
          </div>
        </div>
      </section>
      <section
        className="section section--gradient"
        style={{ backgroundColor: colors.offWhite }}
      >
        <div className="container">
          <div className="columns">
            <div className="column" />
            <div className="column is-four-fifths">
              {description.split(". ").map((line, index) => (
                <p key={index} style={{ padding: "14px 0" }}>
                  {line}
                </p>
              ))}
            </div>
            <div className="column" />
          </div>
        </div>
      </section>
    </>
  );
};

ProductPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  shortDescription: PropTypes.string,
  featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  id: PropTypes.string,
  tags: PropTypes.array
};

const ProductPage = ({ data }) => {
  const { frontmatter, id } = data.markdownRemark;
  return (
    <Layout>
      <ProductPageTemplate
        featuredImage={frontmatter.featuredImage}
        title={frontmatter.title}
        description={frontmatter.description}
        shortDescription={frontmatter.shortDescription}
        price={frontmatter.price}
        tags={frontmatter.tags}
        id={id}
        images={frontmatter.images || []}
        variantLabel={frontmatter.variantLabel}
        variants={frontmatter.variants || []}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        shortDescription
        description
        tags
        price
        variantLabel
        variants {
          variantName
          variantPrice
        }
        images {
          src {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
    }
  }
`;
