import React from "react";
import styled from "styled-components";
import {colors} from "../styles/variables";
import {Link} from "gatsby";
import {kebabCase} from "lodash";

const TagsStyled = styled.ul`
  border-top: 1px dashed ${colors.gold};
  display: inline-block;
  list-style-type: none;
  padding: 8.5px 0 0;
  margin: 30px 0;
  li {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin: 0 0 10px 0;
    font-weight: 700;
    font-size: 11px;
    display: block;
    color: ${colors.grey};
    border-bottom: 1px dashed #cfcfcf;
    &:hover a {
      transform: translateX(10px);
    }
    a {
      display: inline-block;
      padding: 0 40px 10px 0;
      min-width: 185px;
      position: relative;
      transition: 0.45s;
      &:before {
        content: "→";
        font-size: 20px;
        color: ${colors.gold};
        vertical-align: sub;
        margin-right: 10px;
      }
    }
  }
`;


export const Tags = ({tags}) => (
    <TagsStyled>
        {tags.map((tag, index) => (
            <li key={index}>
                <Link to={`/shop/tags/${kebabCase(tag)}/`}>
                    <span>{tag}</span>
                </Link>
            </li>
        ))}
    </TagsStyled>
);
